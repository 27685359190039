import { useEffect } from "react";

export default function Store() {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("android") > -1) {
      //안드로이드일 때 실행할 동작
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ceunnseo.reMIND";
    } else if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    ) {
      //IOS일 때 실행할 동작
      window.location.href =
        "https://apps.apple.com/kr/app/%EB%A6%AC%EB%A7%88%EC%9D%B8%EB%93%9C-ai-%EA%B0%95%EC%95%84%EC%A7%80%EC%99%80-%ED%95%A8%EA%BB%98%ED%95%98%EB%8A%94-%ED%9E%90%EB%A7%81%EC%B1%84%ED%8C%85/id6544783154";
    } else {
      //아이폰, 안드로이드가 아닐 때 실행할 동작
      window.location.href = "/";
    }
  }, []);

  return <></>;
}
